<template>
    <div>
        <c-search-box @enter="getList">
            <template slot="search">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <c-plant
                        type="search"
                        name="plantCd"
                        v-model="searchParam.plantCd"
                    />
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <!-- 진행단계 -->
                    <c-select
                        type="search"
                        stepperGrpCd="SWP_STEP_CD"
                        itemText="stepperMstNm"
                        itemValue="stepperMstCd"
                        name="swpStepCd"
                        label="진행단계"
                        v-model="searchParam.swpStepCd"
                    ></c-select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <!-- 작업기간 -->
                    <c-datepicker
                        :range="true"
                        defaultStart="-6M"
                        defaultEnd="6M"
                        label="작업기간"
                        name="permitDates"
                        v-model="searchParam.permitDates"
                    />
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <!-- 신청부서 -->
                    <c-dept
                        type="search"
                        :isFirstValue="false"
                        label="신청부서"
                        name="applicationDeptCd"
                        v-model="searchParam.applicationDeptCd"
                    />
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <!-- 발급부서 -->
                    <c-dept
                        type="search"
                        :isFirstValue="false"
                        label="발급부서"
                        name="issuedDeptCd"
                        v-model="searchParam.issuedDeptCd"
                    />
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <!-- 허가서구분 -->
                    <c-select
                        type="search"
                        codeGroupCd="SOP_PERMIT_TYPE_CD"
                        itemText="codeName"
                        itemValue="code"
                        name="permitTypeCd"
                        label="허가서구분"
                        v-model="searchParam.permitTypeCd"
                    ></c-select>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <!-- 보충작업 -->
                    <c-select
                        type="search"
                        codeGroupCd="SOP_SUPPLEMENT_WORK_TYPE_CD"
                        itemText="codeName"
                        itemValue="code"
                        name="supplementWorkTypeCd"
                        label="보충작업"
                        v-model="searchParam.supplementWorkTypeCd"
                    ></c-select>
                </div>
            </template>
        </c-search-box>
        <div class="card cardcontents">
            <!-- <div class="card-header q-table-header">
        작업허가서
        <div class="card-more">
          <q-btn-group outline >
            <c-btn label="화기 등록" icon="add" @btnClicked="addFire" />
            <c-btn label="일반 등록" icon="add" @btnClicked="addNormal" />
          </q-btn-group>
        </div>
      </div> -->
            <div class="card-body qtable-card-body">
                <q-table
                    grid
                    class="qtable-card"
                    title=""
                    :data="grid.data"
                    :columns="grid.columns"
                    hide-header
                    hide-bottom
                    :rows-per-page-options="[0]"
                    virtual-scroll
                >
                    <template v-slot:top-right> </template>
                    <template v-slot:item="props">
                        <div
                            class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                        >
                            <q-card
                                class="mobileTableCardLayer"
                                @click="linkClick(null, props.row)"
                            >
                                <q-card-section class="grid-card-etc">
                                    <div
                                        :class="[
                                            'text-grid-etc',
                                            getColorStatus(props.row.swpStepCd),
                                        ]"
                                    >
                                        {{ props.row.swpStepName }}
                                    </div>
                                    <div
                                        :class="[
                                            'text-grid-etc',
                                            getColorStatus2(
                                                props.row.permitTypeCd
                                            ),
                                        ]"
                                    >
                                        {{ props.row.permitTypeName }}
                                    </div>
                                    <div
                                        :class="[
                                            'text-grid-etc',
                                            getColorStatus3(
                                                props.row.approvalStatusCd
                                            ),
                                        ]"
                                        v-if="props.row.approvalStatusCd"
                                    >
                                        {{ props.row.approvalStatusName }}
                                    </div>
                                </q-card-section>
                                <q-card-section>
                                    <div
                                        class="text-grid-title q-mt-sm q-mb-xs"
                                    >
                                        {{ props.row.workSummary }}
                                    </div>
                                </q-card-section>
                                <q-card-section class="grid-card-etc">
                                    <div class="text-grid-etc">
                                        {{ props.row.permitDate }} |
                                        {{
                                            getSupWorkName(
                                                props.row
                                                    .supplementWorkTypeNames
                                            )
                                        }}
                                    </div>
                                </q-card-section>
                            </q-card>
                        </div>
                    </template>
                </q-table>
                <div
                    class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                    v-if="grid.data.length === 0"
                >
                    <q-card class="mobileTableCardLayer">
                        <q-card-section class="text-center">
                            <q-card-section class="q-pt-xs">
                                <div class="text-grid-title q-mt-sm q-mb-xs">
                                    데이터가 존재하지 않습니다.
                                </div>
                            </q-card-section>
                        </q-card-section>
                    </q-card>
                </div>
            </div>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
    </div>
</template>

<script>
import selectConfig from "@/js/selectConfig";
export default {
    name: "safe-work-permit",
    data() {
        return {
            searchParam: {
                plantCd: null,
                permitDates: [],
                applicationDeptCd: null,
                issuedDeptCd: null,
                permitTypeCd: null,
                supplementWorkTypeCd: null,
                swpStepCd: null,
            },
            grid: {
                columns: [
                    // {
                    //   name: 'plantName',
                    //   field: 'plantName',
                    //   label: '사업장',
                    //   align: 'center',
                    //   sortable: true,
                    //   style: 'width:100px',
                    // },
                    {
                        name: "workSummary",
                        field: "workSummary",
                        label: "작업개요",
                        align: "left",
                        sortable: true,
                        type: "link",
                        style: "width:70%",
                    },
                    {
                        name: "swpStepName",
                        field: "swpStepName",
                        label: "진행단계",
                        align: "center",
                        sortable: true,
                        style: "width:100px",
                    },
                    // {
                    //   name: 'bizApprStepNm',
                    //   field: 'bizApprStepNm',
                    //   label: '결재진행단계',
                    //   align: 'center',
                    //   sortable: true,
                    //   style: 'width:100px',
                    // },
                    {
                        name: "permitDate",
                        field: "permitDate",
                        label: "허가일자",
                        align: "center",
                        sortable: true,
                        style: "width:30%",
                    },
                    // {
                    //   name: 'permitTypeName',
                    //   field: 'permitTypeName',
                    //   label: '허가서구분',
                    //   align: 'center',
                    //   sortable: true,
                    //   style: 'width:100px',
                    // },
                    // {
                    //   name: 'supplementWorkTypeNames',
                    //   field: 'supplementWorkTypeNames',
                    //   label: '보충작업',
                    //   align: 'left',
                    //   sortable: true,
                    //   style: 'width:100px',
                    // },
                    // {
                    //   name: 'applicationDeptName',
                    //   field: 'applicationDeptName',
                    //   label: '신청부서',
                    //   align: 'center',
                    //   sortable: true,
                    //   style: 'width:100px',
                    // },
                    // {
                    //   name: 'issuedDeptName',
                    //   field: 'issuedDeptName',
                    //   label: '발급부서',
                    //   align: 'center',
                    //   sortable: true,
                    //   style: 'width:100px',
                    // },
                ],
                data: [],
            },
            editable: true,
            listUrl: "",
            popupOptions: {
                isFull: true,
                target: null,
                title: "",
                visible: false,
                param: {},
                closeCallback: null,
            },
        };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
        Object.assign(this.$data, this.$options.data());
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {},
    beforeRouteLeave(to, from, next) {
        // history.back 일경우 팝업이라면 팝업을 닫자
        if (this.popupOptions.visible) {
            this.closePopup();
            next(false);
        } else {
            next();
        }
    },
    methods: {
        init() {
            // role setting
            this.editable = this.$route.meta.editable;
            // url setting
            this.listUrl = selectConfig.sop.swp.workPermit.list.url;
            // code setting
            if (this.$route.query.sopWorkPermitId) {
                this.getWorkDetail();
            }
            // list setting
            this.getList();
        },
        getColorStatus(cd) {
            var cls = "";
            switch (cd) {
                case "SS00000001": // 작성중
                    cls = "txt-box-grid text-primary-box";
                    break;
                case "SS00000005": // 승인완료
                    cls = "txt-box-grid text-positive-box";
                    break;
                case "SS00000010": // 연장
                    cls = "txt-box-grid text-accent-box";
                    break;
                case "SS00000015": // 취소
                    cls = "txt-box-grid text-red-box";
                    break;
                case "SS00000020": // 작업완료
                    cls = "txt-box-grid text-orange-box";
                    break;
            }
            return cls;
        },
        getColorStatus2(cd) {
            var cls = "";
            switch (cd) {
                case "SPT0000001": // 일반
                    cls = "txt-box-grid text-primary-box";
                    break;
                case "SPT0000005": // 화기
                    cls = "txt-box-grid text-red-box";
                    break;
            }
            return cls;
        },
        getColorStatus3(cd) {
            var cls = "";
            switch (cd) {
                case "ASC0000001": // 결재중
                    cls = "txt-box-grid text-gray-box text-blue";
                    break;
                case "ASC9999999": // 결재완료
                    cls = "txt-box-grid text-gray-box text-red";
                    break;
            }
            return cls;
        },
        getList() {
            this.$http.url = this.listUrl;
            this.$http.type = "GET";
            this.$http.param = this.searchParam;
            this.$http.request((_result) => {
                this.grid.data = _result.data;
            });
        },
        getWorkDetail() {
            if (
                this.$route.query.sopWorkPermitId &&
                this.$route.query.permitTypeCd
            ) {
                this.popupOptions.target = () =>
                    import(`${"./safeWorkPermitDetail.vue"}`);
                this.popupOptions.title = "작업허가서";
                this.popupOptions.param = {
                    sopWorkPermitId: this.$route.query.sopWorkPermitId,
                    permitTypeCd: this.$route.query.permitTypeCd,
                };
                this.popupOptions.isFull = true;
                // this.popupOptions.width = '95%';
                this.popupOptions.visible = true;
                this.popupOptions.closeCallback = this.closePopup;
            } else {
                window.getApp.$emit("ALERT", {
                    title: "안내", // 안내
                    message: "존재하지 않는 작업허가서입니다.",
                    type: "warning", // success / info / warning / error
                });
            }
        },
        /* eslint-disable no-unused-vars */
        addFire() {
            // 화기 작업등록
            this.linkClick(null, { permitTypeCd: "SPT0000005" });
        },
        addNormal() {
            // 일반 작업등록
            this.linkClick(null, { permitTypeCd: "SPT0000001" });
        },
        linkClick(evt, row) {
            this.popupOptions.title = "작업허가서";
            this.popupOptions.param = {
                sopWorkPermitId: row ? row.sopWorkPermitId : "",
                permitTypeCd: row ? row.permitTypeCd : "",
            };
            this.popupOptions.target = () =>
                import(`${"./safeWorkPermitDetail.vue"}`);
            this.popupOptions.visible = true;
            this.popupOptions.closeCallback = this.closePopup;
        },
        closePopup() {
            this.popupOptions.target = null;
            this.popupOptions.visible = false;
            this.getList();
        },
        getSupWorkName(_val) {
            if (_val === null) {
                return "";
            } else {
                return "보충작업(" + _val + ")";
            }
        },
    },
};
</script>
